<template>
  <div id="formulario-edit-info">   
    <ExibirErrosConcept :errorsValidacao="errorsValidacao" ref="errosComponent"></ExibirErrosConcept>
    <vx-card>
        <div class="vx-row mb-4">
            <div class="vx-col md:w-1/2 w-full">                    
                <vs-button class="ml-auto mt-2" size="small" @click="gravar" v-show="isUpdatable && isAllowedToSave" color="success">Gravar</vs-button>
                <vs-button class="ml-4 mt-2" size="small" @click="excluir" v-show="isDeletable && isAllowedToSave" color="danger">Excluir</vs-button>            
                <vs-button class="ml-4 mt-2" size="small" type="border" color="dark" @click="voltar">Voltar</vs-button>             
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col w-1/2" v-if="isEmpresaProprietaria"><label class="vs-input--label">Empresa Proprietária</label></div>     
            <div class="vx-col w-1/2"><label class="vs-input--label">Empresa Utilizadora</label></div>                         
        </div>    
        <div class="vx-row">
            <div class="vx-col w-1/2" v-if="isEmpresaProprietaria">                
                <v-select  
                    label="nome"      
                    :options="listaEmpresas"                      
                    :reduce="c => c.id"                                                
                    v-model="data_local.idEmpresaProprietaria"                                              
                    :disabled="true"                
                    class="w-full vs-input--label">
                    <template v-slot:option="option">                        
                        {{ option.nome }}
                    </template>
                </v-select>  
            </div>
            <div class="vx-col w-1/2">                
                <v-select  
                    label="nome"      
                    :options="listaEmpresas"                      
                    :reduce="c => c.id"                                
                    :disabled="isNaoPermitirAlterarEmpresa"                
                    v-model="data_local.idEmpresa"                          
                    class="w-full vs-input--label">
                    <template v-slot:option="option">                        
                        {{ option.nome }}
                    </template>
                </v-select>  
            </div>
        </div>
        <div class="vx-row">            
            <div class="vx-col w-full"><label class="vs-input--label">Cliente</label></div>
        </div>    
        <div class="vx-row">
            <div class="vx-col w-full">
                 <v-select
                    label="nome"  
                    :reduce="r => r.id"                                           
                    :options="listaClientes" 
                    v-model="data_local.idCliente"                      
                    class="w-full vs-input--label">
                </v-select> 
            </div>    
        </div>   
        <div class="vx-row">
            <div class="vx-col w-1/3"><label class="vs-input--label">Número GSM</label></div>     
            <div class="vx-col w-1/3"><label class="vs-input--label">Operadora</label></div>     
            <div class="vx-col w-1/3"><label class="vs-input--label">ICCID</label></div>                 
        </div>   
        <div class="vx-row items-center">
            <div class="vx-col w-1/3">                
                <vs-input class="w-full" v-model="data_local.numeroGSM" :disabled="registroOriginal && registroOriginal.numeroGSM" />
            </div>  
            <div class="vx-col w-1/3">
                 <v-select                                               
                    :options="CONSTANTE_OPERADORAS_GSM" 
                    :reduce="r => r.data"                    
                    v-model="data_local.operadoraGSM"  
                    :disabled="semSimcard"                    
                    class="w-full vs-input--label">
                </v-select>  
            </div>    
            <div class="vx-col w-1/3">                
                <vs-input class="w-full" v-model="data_local.iccid"  />
            </div>            
        </div>   
        <div class="vx-row">
            <div class="vx-col w-1/4"><label class="vs-input--label">Valor Mensal</label></div>                 
            <div class="vx-col w-1/4" v-if="isEmpresaProprietaria"><label class="vs-input--label">Valor Custo</label></div>                             
        </div>   
        <div class="vx-row items-center">
            <div class="vx-col w-1/4">                
                <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full" v-model="data_local.valorMensal" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'2' }]"></vue-autonumeric>
            </div>
            <div class="vx-col w-1/4" v-if="isEmpresaProprietaria" >                
                <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full" v-model="data_local.valorCusto" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'2' }]"></vue-autonumeric>
            </div>            
        </div>
        <div class="vx-row">            
            <div class="vx-col w-1/4"><label class="vs-input--label">Data Ativação</label></div>     
            <div class="vx-col w-1/4"><label class="vs-input--label">Data Inativação</label></div>                 
            <div class="vx-col w-1/4"><label class="vs-input--label">Módulo Ativo</label></div>     
        </div>   
         <div class="vx-row items-center">            
            <div class="vx-col w-1/4 flex items-center">                
                <flat-pickr v-model="data_local.dataInclusao" :config="configDatePicker"/>
                <vs-button class="ml-2" size="small" icon="clear" color="dark" @click="data_local.dataInclusao = null"></vs-button> 
            </div>
            <div class="vx-col w-1/4  flex items-center">                
                <flat-pickr v-model="data_local.dataExclusao" :config="configDatePicker"/>
                <vs-button class="ml-2" size="small" icon="clear" color="dark" @click="data_local.dataExclusao = null"></vs-button> 
            </div>
            <div class="vx-col w-1/4">                
                {{data_local.codigoModulo}}
            </div>
        </div>

        <div class="vx-row mt-2">            
            <div class="vx-col w-2/3"><label class="vs-input--label mr-2">Observação</label></div>     
        </div>    
        <div class="vx-row">                
            <div class="vx-col w-2/3">
                <vs-textarea v-model="data_local.observacoes" counter="1024" />
            </div>
        </div>
        
    </vx-card>  
  </div>
</template>

<script>
import axios from "@/axios.js"
import { CONSTANTE_OPERADORAS_GSM } from '@/constantesComboBox.js'
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Portuguese as PortugueseLocale} from 'flatpickr/dist/l10n/pt.js';

const URL_SERVLET = "/SimcardRest";
const NAME_ROUTE_SEARCH = "cadastro-simcard-pesquisar";

export default {   
  components: {  
    flatPickr,
    VueAutonumeric
  },   
  props: {
    registroOriginal: {
      type: Object,
      required: false,
      default: function () {
            return { idEmpresaProprietaria: null, idEmpresa: null, numeroGSM: null, operadoraGSM: null, iccid: null, valorMensal: null, valorCusto: null, dataInclusao: null, dataExclusao: null, observacoes: null, idCliente: null }
       }
    },
  },
  data() {
    return {        
        errorsValidacao: [],  
        data_local: JSON.parse(JSON.stringify(this.registroOriginal)),          
        listaEmpresas: [], 
        listaClientes : [],
        configDatePicker: {          
            altInput: true,
            altFormat: "d-m-Y",
            dateFormat: "d/m/Y", 
            altInputClass: "vs-inputx vs-input--input normal hasValue text-center",
            locale: PortugueseLocale
        }, 
    }
  },  
  created(){
      this.CONSTANTE_OPERADORAS_GSM = CONSTANTE_OPERADORAS_GSM;
  }, 
  mounted() {
      this.fetchListaEmpresas();
      this.fetchListaClientes();

      if (!this.registroOriginal.numeroGSM) {
          this.data_local.idEmpresaProprietaria = this.$store.state.AppActiveUser.idEmpresa;
          this.data_local.idEmpresa = this.$store.state.AppActiveUser.idEmpresa;
      }
  },
  computed: {  
    isEmpresaProprietaria() {
        return this.$store.state.AppActiveUser.idEmpresa === this.data_local.idEmpresaProprietaria;
    } ,
    isAllowedToSave() {
       let perfilLogado = this.$store.state.AppActiveUser.perfil;
       if (perfilLogado === "CONFIGURADOR" || perfilLogado === "FINANCEIRO" || perfilLogado === "MONITOR_PANICO") {
            return false;	
       } 

       return true;
    },
    isUpdatable(){
        if (!this.registroOriginal.numeroGSM) {
            //Novo registro
            return true;
        } else if (this.registroOriginal.idEmpresaProprietaria !== this.$store.state.AppActiveUser.idEmpresa) {
            return false;
        }

        return true;
    },   
    isDeletable() {
        if (this.data_local != null && this.registroOriginal && this.registroOriginal.idEmpresaProprietaria !== this.$store.state.AppActiveUser.idEmpresa) {
            return false;
        }
       return this.data_local != null && this.registroOriginal.numeroGSM;
    },    
    isNaoPermitirAlterarEmpresa() {
        if (this.registroOriginal && this.registroOriginal.idEmpresaProprietaria !== this.$store.state.AppActiveUser.idEmpresa) {
            return true;
        }

        let idEmpresa = this.$store.state.AppActiveUser.idEmpresa;
        if (idEmpresa === 1 || idEmpresa === 40) {
            return false;
        }

        return true;
    }
  },
  methods: {  
    fetchListaClientes() {     
      this.$vs.loading() 
      const params = new URLSearchParams();
      params.append('method', 'FindAll');
      params.append('orderDirection', 'ASC');
      params.append('outputFormat', 'JSON');
            
      axios.post("/ClientesRest", params, {              
              credentials: 'include',
              responseEncoding: 'iso88591',
              withCredentials: true } )
      .then((response) => {
          this.$vs.loading.close();
          if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
              this.$router.push({ path: '/' });
          } else {
              this.listaClientes = response.data;         
          }
      })
      .catch((error) => { 
          this.$vs.loading.close();
          /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchListaClientes - " + error); 
      })    
                        
    }, 
    excluir() {
        let perfilLogado = this.$store.state.AppActiveUser.perfil;
        if (perfilLogado === "ADMINISTRADOR" || perfilLogado === "SUPERVISOR" || perfilLogado === "EMPRESA_SUPERVISOR" || perfilLogado === "EMPRESA") {
            this.$vs.dialog({
                type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja excluir ?', acceptText: 'Sim', cancelText: 'Não', accept: this.excluirRequest
            });
        } else {
            this.$vs.dialog({ color: 'danger', title: 'Erro', text: 'Você não tem permissão para excluir um SIMCARD', acceptText: 'OK'})
        }
    },
    excluirRequest() {
        this.$vs.loading();  
        const params = new URLSearchParams();        
        params.append('outputFormat', 'JSON');
        params.append('method', 'Delete');      
        params.append('numeroGSM', this.data_local.numeroGSM);   
        params.append('idEmpresa', this.data_local.idEmpresa);                  
                
        axios.post(URL_SERVLET, params, {              
                credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
        .then((response) => {
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("error")) {
                        this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.error, acceptText: 'OK'})
                } else {
                    this.$router.push({ name: NAME_ROUTE_SEARCH })
                }                   
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
        })  
    },
    gravar() {        
        if (this.formularioValido()) { 
            this.$vs.loading();  
            const params = new URLSearchParams();        
            params.append('outputFormat', 'JSON');
            
            if (this.registroOriginal.numeroGSM) {
                params.append('method', 'Update');                      
            }  else {
                params.append('method', 'Insert');      
            }  

            params.append('idEmpresaProprietaria', this.data_local.idEmpresaProprietaria);                  
            params.append('numeroGSM', this.data_local.numeroGSM);      
            params.append('operadoraGSM', this.data_local.operadoraGSM);      
            params.append('idEmpresa', this.data_local.idEmpresa);                  
            params.append('valorMensal', this.data_local.valorMensal);                  
            params.append('iccid', this.data_local.iccid);                              
            params.append('dataInclusao', this.data_local.dataInclusao);
            params.append('observacoes', this.data_local.observacoes);
            params.append('valorCusto', this.data_local.valorCusto);                  
                        
            if (this.data_local.dataExclusao) {
                params.append('dataExclusao', this.data_local.dataExclusao);      
            }

            if (this.data_local.idCliente) {
                params.append('idCliente', this.data_local.idCliente);      
            }
           
            axios.post(URL_SERVLET, params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    if (response.data.hasOwnProperty("error")) {
                            this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.error, acceptText: 'OK'})
                    } else {
                        if (!this.registroOriginal.hasOwnProperty('numeroGSM') || !this.registroOriginal.numeroGSM) {
                            this.$router.push({ name: NAME_ROUTE_SEARCH })
                        } else {
                            this.$vs.notify({
                                title: "Mensagem", text: "Simcard alterado.", time: 10000,
                                iconPack: 'feather', icon: 'icon-alert-circle', color: 'success' });
                        }
                    }                   
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
            })  
        }
         
    },
    voltar() {
        this.$router.push({
            name: NAME_ROUTE_SEARCH                     
        })
    },
    formularioValido() {
        this.$refs.errosComponent.iniciar();
        this.errorsValidacao = [];
      
      
      if (!this.data_local.idEmpresaProprietaria) {
            this.errorsValidacao.push("Informe a empresa proprietária");
        }
        if (!this.data_local.idEmpresa) {
            this.errorsValidacao.push("Informe a empresa");
        }

        if (!this.data_local.numeroGSM) {
            this.errorsValidacao.push("Numero GSM obrigatório");
        }

        if (!this.data_local.operadoraGSM) {
            this.errorsValidacao.push("Operadora obrigatório");
        }

        if (!this.data_local.dataInclusao) {
            this.errorsValidacao.push("Data de inclusão obrigatório");
        }

        if (!this.data_local.valorMensal) {
            this.errorsValidacao.push("Valor Mensal obrigatório");
        }

        if (!this.data_local.valorCusto) {
            this.errorsValidacao.push("Valor Custo obrigatório");
        }

        if (!this.errorsValidacao.length) {
            return true;
        }   
        return false;
    },
    fetchListaEmpresas() {     
        this.$vs.loading() 
        const params = new URLSearchParams();
        params.append('outputFormat', 'JSON');
        
        axios.post("/ListarEmpresas", params, {              
                credentials: 'include',
                responseEncoding: 'iso88591',
                withCredentials: true } )
        .then((response) => {
            
            this.$vs.loading.close();
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {
                this.listaEmpresas = response.data;                            
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
        })    
                            
    },
  }
}
</script>

<style scoped>
  .tabelaComScroll {      
      overflow: auto;  
      height: calc(100vh - 300px);
      position: relative;      
    } 
</style>